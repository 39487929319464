<template>
  <v-container class="pa-0" fluid>
    <v-row align="center" no-gutters>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" class="vh-100" cols="4">
        <kurcc-auth-intro :title="$vuetify.lang.t('$vuetify.general.becomeAVendor')"/>
      </v-col>
      <v-col cols="12" md="8">
        <v-stepper v-model="currentStep" class="rounded-0 v-card--flat" non-linear vertical>
          <template v-for="step in steps">
            <v-stepper-step :key="(1001+step.number)" :color="getColor(step.number)"
                            :complete="isValid(step.number) && !isLastStep(step.number)"
                            :editable="isEditable(step.number)" :step="step.number"
                            @click="setCurrentStep(step.number)">
              {{ $vuetify.lang.t(step.title) }}
            </v-stepper-step>

            <v-stepper-content :key="(999-step.number)" :step="step.number">
              <validation-observer ref="observer">
                <component v-bind:is="`KurccSiteStepperStep${step.number}Content`" :site.sync="site"/>
              </validation-observer>

              <v-col v-if="isLastStep(step.number)" class="mt-6" cols="12">
                <v-btn :disabled="isFormValid" :loading="creatingSite" color="secondary" @click="createSite">Submit
                </v-btn>
              </v-col>

              <kurcc-site-stepper-step-continue-button v-else :current-step.sync="currentStep"
                                                       :disabled="!isValid(step.number)"
                                                       class="mb-5"/>
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Site from '@/modules/app/models/site'
import { SHOW_SNACKBAR_ERROR, SHOW_SNACKBAR_SUCCESS } from '@/modules/app/store/mutation-types'
import steps from '../enums/site-steps'

export default {
  name: 'KurccSiteFormPage',
  components: {
    KurccSiteStepperStep1Content: () => import('@/modules/site/components/KurccSiteStepperStep1Content'),
    KurccSiteStepperStep2Content: () => import('@/modules/site/components/KurccSiteStepperStep2Content'),
    KurccSiteStepperStep3Content: () => import('@/modules/site/components/KurccSiteStepperStep3Content'),
    KurccSiteStepperStep4Content: () => import('@/modules/site/components/KurccSiteStepperStep4Content'),
    KurccSiteStepperStep5Content: () => import('@/modules/site/components/KurccSiteStepperStep5Content'),
    KurccSiteStepperStep6Content: () => import('@/modules/site/components/KurccSiteStepperStep6Content'),
    KurccSiteStepperStep7Content: () => import('@/modules/site/components/KurccSiteStepperStep7Content'),
    KurccSiteStepperStep8Content: () => import('@/modules/site/components/KurccSiteStepperStep8Content'),
    KurccSiteStepperStep9Content: () => import('@/modules/site/components/KurccSiteStepperStep9Content'),
    KurccSiteStepperStepContinueButton: () => import('@/modules/site/components/KurccSiteStepperStepContinueButton'),
    KurccAuthIntro: () => import('@/modules/auth/components/KurccAuthIntro'),
    ValidationObserver
  },
  data () {
    return {
      currentStep: 1,
      steps,
      site: new Site(),
      creatingSite: false
    }
  },
  computed: {
    isFormValid () {
      let isValid = true
      this.$lodash.forEach(this.steps, step => {
        if (!this.isValid(step.number)) isValid = false
      })
      return isValid
    }
  },
  methods: {
    createSite () {
      this.creatingSite = true
      this.$store.dispatch('createSite', { ...this.site })
        .then(() => {
          this.$store.commit(SHOW_SNACKBAR_SUCCESS, 'Site Created')
          this.createBranch()
        })
        .catch(reason => {
          this.$store.commit(SHOW_SNACKBAR_ERROR, reason)
          this.creatingSite = false
        })
    },
    createBranch () {
      return this.$store.dispatch('createBranch', { ...this.site.branch })
        .catch(() => this.$store.commit(SHOW_SNACKBAR_ERROR, 'There was a problem while creating Branch. Try again though dashboard.'))
        .finally(() => {
          this.$router.push({ name: 'dashboard' })
          this.creatingSite = false
        })
    },
    getColor (step) {
      if (step === this.currentStep) {
        return 'primary'
      } else {
        return this.isValid(step) ? 'secondary' : 'grey'
      }
    },
    firstIncompleteStep (step) {
      if (step === 1) return true

      if (this.$refs.observer && this.$refs.observer[step - 2]) {
        return this.$refs.observer[step - 2].flags.valid
      } else {
        return false
      }
    },
    isValid (step) {
      if (this.$refs.observer && this.$refs.observer[step - 1]) {
        return this.$refs.observer[step - 1].flags.valid
      } else {
        return false
      }
    },
    isEditable (step) {
      if (this.isLastStep(step)) {
        return this.isFormValid || this.firstIncompleteStep(step)
      } else {
        return this.isValid(step) || this.firstIncompleteStep(step)
      }
    },
    setCurrentStep (step) {
      if (this.isEditable(step)) {
        this.currentStep = step
      }
    },
    isLastStep (step) {
      return step === Object.keys(this.steps).length
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$auth.user().site ? vm.$router.push({ name: 'dashboard' }) : next()
    })
  }
}
</script>
